import { Route } from 'next';
import Link from 'next/link';
import styles from './CommonFooter.module.scss';

function CommonFooter({
  siteName,
  copyright,
}: {
  siteName: string;
  copyright: string;
}) {
  // toはサイト内リンク、hrefは外部リンク
  const menuListLeft = () => {
    const list = [
      { to: '/auth/signin', text: 'ログイン・新規会員登録' },
      { to: '/faq', text: 'よくある質問' },
      { to: '/lotteries?tab=is_session', text: '開催中のくじ一覧' },
      { to: '/informations', text: 'お知らせ' },
    ];
    return list;
  };
  const menuListRight = () => {
    return [
      { to: '/terms', text: '利用規約' },
      {
        href: `${process.env.NEXT_PUBLIC_MAIN_URL}/policy`,
        text: 'プライバシーポリシー',
      },
      { to: '/tokusho', text: '特定商取引法に基づく表記' },
      { to: '/shikin', text: '資金決済法に基づく表記' },
      { to: '/keihin', text: '景品のお取り扱いについての注意事項' },
    ];
  };
  return (
    <footer className={styles.componentStyle}>
      <div className={styles.container}>
        <div className={styles.footerTitle}>
          <img src='/logo-white.png' alt={siteName} />
        </div>
        <div className={styles.menuListWrapper}>
          <ul className={styles.menuList}>{menuList(menuListLeft())}</ul>
          <ul className={styles.menuList}>{menuList(menuListRight())}</ul>
        </div>
        <p className={styles.copyright}>{copyright}</p>
      </div>
    </footer>
  );
}

function menuList(list: Array<{ href?: string; to?: string; text: string }>) {
  const result: Array<React.ReactNode> = [];
  list.forEach((item, index) => {
    result.push(
      <li key={`menu_${index}`}>
        {item.href ? (
          <a href={item.href}>{item.text}</a>
        ) : (
          <Link href={item.to as Route} prefetch={false}>
            {item.text}
          </Link>
        )}
      </li>,
    );
  });

  return result;
}

export default CommonFooter;
